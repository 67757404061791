<route>
{
	"name": "forgot-password",
  	"meta": {
    	"guestAccess": true
  }
}
</route>
<template>
	<div class="min-h-screen bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8 bg-sc-login">
		<div class="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
			<div class="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10" :class="{ 'pointer-events-none': loading }">
				<h2 class="my-6 text-center text-2xl font-extrabold text-gray-600">
					Account Recovery
				</h2>
				<form class="space-y-6" action="#" method="POST" @submit.prevent="handleForgotPassoword">
					<div>
						<label for="email" class="block text-sm font-medium text-gray-700">
							Email
						</label>
						<div class="mt-1">
							<input
								id="email"
								v-model="email"
								name="email"
								type="text"
								autocomplete="email"
								required=""
								class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
							/>
						</div>
					</div>

					<div>
						<button
							type="submit"
							class="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm font-medium text-white bg-sc-blue hover:bg-sc-blue-hover"
							:disabled="loading"
						>
							<IconButtonLoading :loading="loading" />
							Send Password Recovery Email
						</button>
					</div>
				</form>
			</div>
		</div>
	</div>
</template>

<script>
import { ref, computed, inject } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { useStore } from 'vuex'
import { useToast } from 'vue-toastification'

export default {
	name: 'Login',
	layout: 'full',
	setup() {
		const email = ref('')

		const toast = useToast()

		const router = useRouter()
		const api = inject('api')
		//const loggedIn = computed(() => store.getters.user.isLoggedIn)
		//console.log('isLoggedIn: ', loggedIn)
		//console.log('lstore: ', localStorage.getItem('loggedIn'))
		const loading = ref(false)

		const handleForgotPassoword = () => {
			// handle user login
			//console.log('sending login!')
			loading.value = true

			api.users
				.forgotPassword({
					email: email.value,
				})
				.then(response => {
					//console.log('Login Success! Response: ', response)
					toast.clear()
					toast.success('A password reset email was sent to the email address!')
					// router.replace(router?.currentRoute?.value?.query?.redirect ?? '/')
				})
				.catch(error => {
					//console.log('Failed to login: ', error.message)
					toast.error(error.message)
				})
				.finally(response => {
					loading.value = false
				})
		}
		return {
			email,
			handleForgotPassoword,
			store,
			toast,
			loading,
		}
	},
}
</script>

<style scoped lang="scss">
.bg-sc-login {
	background-image: url('/assets/images/rsz_login-page-bg.jpg');
	background-size: cover;
}
.logo {
	margin: 0 auto;
}
</style>
